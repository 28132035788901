export const PRODUCT_STATUS = [
  { label: "Hiển thị", value: "PUBLISHED" },
  {
    label: "Ngừng kinh doanh (Vẫn có thể truy cập khi có link - Phục vụ SEO)",
    value: "SEO_ONLY"
  },
  {
    label:
      "Ẩn (Toàn bộ sản phẩm, SKU sẽ không thể truy cập, liên hệ kỹ thuật để được hỗ trợ)",
    value: "HIDDEN"
  }
];
